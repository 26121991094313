import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import "../styles/Categorias.scss"
import fondos_svgg from "../assets/fondos_svgg.svg"
import cross_exit from "../assets/cross_exit.svg";
import Loading from './Loading'
// import TablaTransacciones from './TablaTransacciones'
import AgregarComentario from './AgregarComentario'
import AgregarProducto from './AgregarProducto'
import AgregarProdTransac from './AgregarProdTransac'
import TablaTransProd from './TablaTransProd'
import RowProductos from './RowProductos'
import ModificarProducto from './ModificarProducto'
import TablaDisponibles from './TablaDisponibles'
import { getProducts } from '../services/getProducts'
import { getSharedWallets } from '../services/getSharedWallets'
import { getMonths } from '../services/getMonths'
import { getCategories } from '../services/getCategories'
import { useWalletName } from '../hooks/useWalletName'
import { useMovimientosProd } from '../hooks/useMovimientosProd'
import ModificarTransProd from './ModificarTransProd'

function CategoriasProductos() {

    const [showAddCategory, setShowAddCategory] = useState(false)
    const [apiUrl, setApiUrl] = useState("")

    const [showAlert, setShowAlert] = useState(false)
    const [showMonth, setShowMonth] = useState(false)
  
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showTransfer, setShowTransfer] = useState(false)
    const [showModifyCategory, setShowModifyCategory] = useState(false)
    const [showModifyProdTrans, setShowModifyProdTrans] = useState(false)
    const [showAddProdTransac, setShowAddProdTransac] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [modifyId, setModifyId] = useState(null)
    const [comments, setComments] = useState([])
    const [showDate, setShowDate] = useState(false)
    const [ifShared, setIfShared] = useState(false)
    const [walletForTransac, setWalletForTransac] = useState(0)
    const [allMonths, setAllMonths] = useState([])
    const [cantProd, setCantProd] = useState("")
    const [nameProd, setNameProd] = useState("")
    const [priceProd, setPriceProd] = useState("")
    const [iconProd, setIconProd] = useState("")
    const [montoTrans, setMontoTrans] = useState("")
    const [prefCurrency, setPrefCurrency] = useState(1)
    const [deuda, setDeuda] = useState([])
    const [deudaMesesAnteriores, setDeudaMesesAnteriores] = useState([])
    // const [totalProductos, setTotalProductos] = useState(0)
    // const [totalDeudas, setTotalDeudas] = useState(0)
    // const [adeudados, setAdeudados] = useState(0)
  
    const [categoryId, setCategoryId] = useState(0);
    const [productos, setProductos] = useState([]);
    const [sharedCategorias,setSharedCategorias] = useState([]);
    const [sharedCategoriasVisual,setSharedCategoriasVisual] = useState([]);
    const [transacciones, setTransacciones] = useState([]);
    const [transaccionesPendientes, setTransaccionesPendientes] = useState([]);
    const [transaccionesPendientesVisibles, setTransaccionesPendientesVisibles] = useState([]);
    const [sharedTransacciones, setSharedTransacciones] = useState([]);
    const [sharedTransaccionesVisibles, setSharedTransaccionesVisibles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activeIngresos, setActiveIngresos] = useState(true);
    const [activeEgresos, setActiveEgresos] = useState(false);
    // const [pendientes, setPendientes] = useState(false);
    const [showContra, setShowContra] = useState(false)
    const [passwordAction, setPasswordAction] = useState("")
    const [months, setMonths] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    const {compartido, walletName} = useWalletName()
    const {gastos} = useMovimientosProd()

      // LISTA BILLETERAS
  useEffect(() => {
    // setLoader(true)
    if (!window.localStorage.getItem("walletId")) {
      setPrefCurrency(1)
      
    } else if(window.localStorage.getItem("compartida")) {
      const TOKEN = window.localStorage.getItem("loggedToken")
      const WALLET_ID = window.localStorage.getItem("walletId")
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(TOKEN)}`
      };
  
      fetch(url,{ headers, withCredentials:true })
      .then(response => response.json())
      .then(data => setPrefCurrency(data.filter(wallet => wallet.id === Number(WALLET_ID))[0].idcurrency))
    } else {
      const TOKEN = window.localStorage.getItem("loggedToken")
      const WALLET_ID = window.localStorage.getItem("walletId")
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(TOKEN)}`
      };
  
      fetch(url,{ headers, withCredentials:true })
      .then(response => response.json())
      .then(data => setPrefCurrency(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.idcurrency))

    }
    // .then(data => console.log(data))
    // .finally(setLoader(false))
  },[])

  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      const token = window.localStorage.getItem("loggedToken");
      if (!token) {
          navigate("/login");
      }

      isMounted.current = true; // Configura el ref a true al final
  }
  }, [navigate, location.pathname]);


    // TRAE TODOS LOS PRODUCTOS
    useEffect(() => {
        if(window.localStorage.getItem("walletId")){
          setLoader(true)
          getProducts()
          .then(data => {setProductos(data);/*setAdeudados(data.reduce((sum, item) => sum + Number(item.total), 0))*/})
          .finally(setLoader(false))
        } else {
            setLoader(true)
            getCategories()
            .then(data => {setProductos(data);/*setTotalProductos(data.reduce((sum, item) => sum + Number(item.total), 0))*/})
            .finally(setLoader(false))
        
      }
    
      },[]);

    // TRAE COMPARTIDOS
      useEffect(() => {
        setLoader(true)
        getSharedWallets()
        .then(data => {divideEditionCat(data?.filter(prods => prods.isproduct === true));divideEditionTransac(data?.filter(prods => prods.isproduct === true));divideEditionDisponibles(data?.filter(prods => prods.isproduct === true))})
        .finally(setLoader(false))
        // eslint-disable-next-line
      },[]);
    
    // TRAE MESES
      useEffect(() => {
        getMonths()
        .then(data => {
          setAllMonths(data?.months);
          /*if(data.months.length === 0){
            calcDeudas(0)
          } else {
            calcDeudas(Number(window.localStorage.getItem("monthId")))
          }*/})
        // .finally(        BringDatos())
      },[])
      // TRANSAC COMPARTIDAS
      // useEffect(() => {
      //   setLoader(true)
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => sharedTransac(data))
      //   .finally(setLoader(false))
      //   // eslint-disable-next-line
      // },[]); 
      async function divideEditionCat(compartidas){
        const PROFILE_ID = window.localStorage.getItem("profileId") 
        // console.log(compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0]) 
        // console.log(compartidas) 
        if (compartidas !== undefined) {
          if (compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0] === Number(PROFILE_ID)) {
            sharedDatos(compartidas)
          } else {
            sharedDatos2(compartidas)
          }
        }
      }

      async function sharedDatos(compartidos){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
        // console.log(compartidos[0].id);
      
        if(compartidos.length === 0){
          setSharedCategorias([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${compartidos[0].id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  // body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setSharedCategorias(data);
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
      async function sharedDatos2(compartidos){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
        // console.log(compartidos);
      
        if(compartidos.length === 0){
          setSharedCategoriasVisual([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${compartidos[0].id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  // body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setSharedCategoriasVisual(data);
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }

        async function divideEditionTransac(compartidas){
          const PROFILE_ID = window.localStorage.getItem("profileId") 
          // console.log(compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0]) 
          if (compartidas !== undefined) {
            if (compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0] === Number(PROFILE_ID)) {
              billeteraCompartida(compartidas)
            } else {
              billeteraCompartida2(compartidas)
            }
          }

        }
    
        async function billeteraCompartida(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:MONTH_ID}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/wallets/get/${cuentas[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    sharedTransac(data.Months);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
        async function billeteraCompartida2(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:MONTH_ID}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/wallets/get/${cuentas[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    sharedTransac2(data.Months);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
        async function sharedTransac(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const formData = {idMonth:cuentas[0].id, idWallet:cuentas[cuentas.length -1].idWallet}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    pruebaCuentas(data);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
        async function sharedTransac2(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const formData = {idMonth:cuentas[0].id, idWallet:cuentas[cuentas.length -1].idWallet}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransaccionesVisibles([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    pruebaCuentas2(data);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
        async function pruebaCuentas(filtrados){
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          // const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(compartidos);
        
          if(filtrados.length === 0){
            console.log([])
          } else {
            setSharedTransacciones(filtrados)
              // try {
              //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/`, {
              //       method: 'GET',
              //       headers: {
              //         'Content-Type': 'application/json',
              //         'Authorization': `Token ${JSON.parse(TOKEN)}`
              //       },
              //       withCredentials:true,
              //       // body: JSON.stringify(formData),
              //     });
                  
              //     if (response.status === 200) {
              //       const data = await response.json();
              //       const perfilPrincipal = data.filter(user => user.id === Number(PROFILE_ID))
              //       setSharedTransacciones(filtrados.filter(cuentas => cuentas.name === perfilPrincipal[0].name));
                    
              //       } else {
              //         console.error('Error submitting form');
              //       }
              //     } catch (error) {
              //       console.error('Error:', error);
              //     }
                }
          }
        async function pruebaCuentas2(filtrados){
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          // const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(compartidos);
        
          if(filtrados.length === 0){
            console.log([])
          } else {
            setSharedTransaccionesVisibles(filtrados)
              // try {
              //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/`, {
              //       method: 'GET',
              //       headers: {
              //         'Content-Type': 'application/json',
              //         'Authorization': `Token ${JSON.parse(TOKEN)}`
              //       },
              //       withCredentials:true,
              //       // body: JSON.stringify(formData),
              //     });
                  
              //     if (response.status === 200) {
              //       const data = await response.json();
              //       const perfilPrincipal = data.filter(user => user.id === Number(PROFILE_ID))
              //       setSharedTransacciones(filtrados.filter(cuentas => cuentas.name === perfilPrincipal[0].name));
                    
              //       } else {
              //         console.error('Error submitting form');
              //       }
              //     } catch (error) {
              //       console.error('Error:', error);
              //     }
                }
          }
    
          // async function calcDeudas(id){
  
          //   const PROFILE_ID = window.localStorage.getItem("profileId")  
          //   const TOKEN = window.localStorage.getItem("loggedToken")
          //   const WALLET_ID = window.localStorage.getItem("walletId")
          
          //   const formData = {month_id:id , id:Number(PROFILE_ID), wallet_id:Number(WALLET_ID)}
          //   // console.log(formData);
          
          //   if(id === 0){
          //       setTotalDeudas([])
          //   } else {
          //       try {
          //           const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/byprod/`, {
          //             method: 'POST',
          //             headers: {
          //               'Content-Type': 'application/json',
          //               'Authorization': `Token ${JSON.parse(TOKEN)}`
          //             },
          //             withCredentials:true,
          //             body: JSON.stringify(formData),
          //           });
                    
          //           if (response.status === 200) {
          //           //   setLoader(false)
          //             const data = await response.json();
          //             // data.pop()
          //             // console.log(data);
          //             setTotalProductos(data.reduce((sum, item) => sum + Number(item.original_total), 0));
          //             setTotalDeudas(data.reduce((sum, item) => sum + Number(item.total_sum), 0));
                                  
          //               // navigate("/home")
          //             } else {
          //               // setLoader(false)
          //               // setShowLoginError(true)
          //               console.error('Error submitting form');
          //             }
          //           } catch (error) {
          //             console.error('Error:', error);
          //           }
          //         }
          //   }
      // useEffect(() => {
      //   setLoader(true)
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/cat/23/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => setSharedCategorias(data))
      //   .finally(setLoader(false))
    
      // },[]);
    
      // useEffect(() => {
    
      //   // setLoader(true)
      //   const PROFILE_ID = window.localStorage.getItem("profileId")
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/transactions/byprof/${PROFILE_ID}/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => setTransacciones(data.transactions.sort((a, b) => new Date(a.date) - new Date(b.date))))
      //   // .finally(setLoader(false))
    
      // },[]);
    
      useEffect(() => {
        getMonths()
        .then(data => setMonths(data?.months[data?.months.length-1]))
    },[])

    // PRUEBA SUBCUENTAS
    // useEffect(() => {
    //   const TOKEN = window.localStorage.getItem("loggedToken")
    //   const MONTH = window.localStorage.getItem("monthId")
    //   const WALLET_ID = window.localStorage.getItem("walletId")
    //   const formData = {month_id:MONTH, wallet_id:WALLET_ID}

    //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/transactions/bywal/full/`;
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Token ${JSON.parse(TOKEN)}`
    //   };
  
    //   fetch(url,{method:"POST", headers, withCredentials:true, body:JSON.stringify(formData)})
    //   .then(response => response.json())
    //   .then(data => console.log(data))
    // },[])
    
      useEffect(() => {
        // setLoader(true)
        getMonths()
        .then(data => {
            if(data?.months.length === 0){
                BringDatos(0)
            } else {
                BringDatos(Number(window.localStorage.getItem("monthId")))
                // BringDatosPendientes(Number(window.localStorage.getItem("monthId")))
            }
        })
      },[])
    
      async function HandleMonth(e){
        const MONTH_ID = e.target.value;
            
        if(Number(MONTH_ID) === months.id){
          window.localStorage.removeItem("prevMonth")
          window.localStorage.removeItem("aproved")
        } else {
          window.localStorage.setItem("prevMonth", true)
        }
        window.localStorage.setItem("monthId", MONTH_ID)
        window.location.reload()
      }
    
      async function BringDatos(id){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
        const WALLET_ID = window.localStorage.getItem("walletId")
      
        const formData = {idMonth:id , idWallet:Number(WALLET_ID)}
        // console.log(formData);
      
        if(id === 0){
          setTransacciones([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  if (window.localStorage.getItem("productId")) {
                    setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).filter((tran) => tran.idProduct === Number(window.localStorage.getItem("productId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                  } else {
                    setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                  }
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
    
        async function divideEditionDisponibles(compartidas){
          const PROFILE_ID = window.localStorage.getItem("profileId") 
          // console.log(compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0])
          if (compartidas !== undefined) {
            if (compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0] === Number(PROFILE_ID)) {
              BringDatosPendientes(compartidas)
            } else {
              BringDatosPendientes2(compartidas)
            }
          } 
        }

        async function BringDatosPendientes(product){
    
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
        
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(product);
        
          if(product.length === 0){
            setTransaccionesPendientes([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${product[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    setTransaccionesPendientes(data);
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
        async function BringDatosPendientes2(product){
    
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
        
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(product);
        
          if(product.length === 0){
            setTransaccionesPendientesVisibles([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${product[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    setTransaccionesPendientesVisibles(data);
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
      function HandleTransactionClick1(){
        setActiveIngresos(true)
        setActiveEgresos(false)
      }
      function HandleTransactionClick2(){
        setActiveIngresos(false)
        setActiveEgresos(true)
      }

      // function renderCurrency(){
      //   if(prefCurrency === 1){
      //     return "USD"
      //   } else if (prefCurrency === 2){
      //     return "MXN"
      //   } else {
      //     return "EUR"
      //   }
      // }

      async function HandleTransacFilter(e){
        const MONTH_ID = e.target.value;
        
        if(Number(MONTH_ID) === 0){
            // const PROFILE_ID = window.localStorage.getItem("profileId")  
            const TOKEN = window.localStorage.getItem("loggedToken")
            const WALLET_ID = window.localStorage.getItem("walletId")
            const MONTH_ID = window.localStorage.getItem("monthId")

          
            const formData = {idMonth:Number(MONTH_ID) , idWallet:Number(WALLET_ID), days_before:0}
            console.log(formData);
          
            if(Number(window.localStorage.getItem("monthId")) === 0){
              setTransacciones([])
            } else {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${JSON.parse(TOKEN)}`
                      },
                      withCredentials:true,
                      body: JSON.stringify(formData),
                    });
                    
                    if (response.status === 200) {
                    //   setLoader(false)
                      const data = await response.json();
                      // console.log(data)
                      if (window.localStorage.getItem("categoryId")) {
                        const filtroBilletera = data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date))
                        setTransacciones(filtroBilletera.filter(tran => tran.idCategory === Number(window.localStorage.getItem("categoryId"))));
                      } else {
                        setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                      }
            
                        // navigate("/home")
                      } else {
                        // setLoader(false)
                        // setShowLoginError(true)
                        console.error('Error submitting form');
                      }
                    } catch (error) {
                      console.error('Error:', error);
                    }
                  }
          
        } else if(Number(MONTH_ID) === 1) {
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const MONTH_ID = window.localStorage.getItem("monthId")
        
          const formData = {idMonth:Number(MONTH_ID) , idWallet:Number(WALLET_ID), days_before:1}
          console.log(formData);
        
          if(Number(window.localStorage.getItem("monthId")) === 0){
            setTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    if (window.localStorage.getItem("categoryId")) {
                      const filtroBilletera = data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date))
                      setTransacciones(filtroBilletera.filter(tran => tran.idCategory === Number(window.localStorage.getItem("categoryId"))));
                    } else {
                      setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                    }
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
        } else if(Number(MONTH_ID) === 3) {
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const MONTH_ID = window.localStorage.getItem("monthId")
        
          const formData = {idMonth:Number(MONTH_ID) , idWallet:Number(WALLET_ID), days_before:3}
          console.log(formData);
        
          if(Number(window.localStorage.getItem("monthId")) === 0){
            setTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    if (window.localStorage.getItem("categoryId")) {
                      const filtroBilletera = data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date))
                      setTransacciones(filtroBilletera.filter(tran => tran.idCategory === Number(window.localStorage.getItem("categoryId"))));
                    } else {
                      setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                    }
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
        } else if(Number(MONTH_ID) === 7) {
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const MONTH_ID = window.localStorage.getItem("monthId")
        
          const formData = {idMonth:Number(MONTH_ID) , idWallet:Number(WALLET_ID), days_before:7}
          console.log(formData);
        
          if(Number(window.localStorage.getItem("monthId")) === 0){
            setTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    if (window.localStorage.getItem("categoryId")) {
                      const filtroBilletera = data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date))
                      setTransacciones(filtroBilletera.filter(tran => tran.idCategory === Number(window.localStorage.getItem("categoryId"))));
                    } else {
                      setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                    }
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
        } else if(Number(MONTH_ID) === 15) {
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const MONTH_ID = window.localStorage.getItem("monthId")
        
          const formData = {idMonth:Number(MONTH_ID) , idWallet:Number(WALLET_ID), days_before:15}
          console.log(formData);
        
          if(Number(window.localStorage.getItem("monthId")) === 0){
            setTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    if (window.localStorage.getItem("categoryId")) {
                      const filtroBilletera = data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date))
                      setTransacciones(filtroBilletera.filter(tran => tran.idCategory === Number(window.localStorage.getItem("categoryId"))));
                    } else {
                      setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
                    }
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
        } 
      }

        // MONTH PARA BUDGET
        useEffect(() => {
          // setLoader(true)
          const TOKEN = window.localStorage.getItem("loggedToken")
          const PROFILE_ID = window.localStorage.getItem("profileId")
          const WALLET_ID = window.localStorage.getItem("walletId")
      
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          // .then(data => setPrefCurrency(data.pref_currency))
          .then(data => DeudasMeses(data))
          // .finally(        BringDatos())
        },[])
      
        async function DeudasMeses(meses){
          const MONTH_ID = window.localStorage.getItem("monthId")
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/debt/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
          
          await fetch(url,{ method:"POST",headers, withCredentials:true })
          .then(response => response.json())
          .then(data => {
            if(data.monthly_totals[data.monthly_totals.length -1]?.month_id === Number(MONTH_ID)){
              setDeuda(data.monthly_totals[data.monthly_totals.length -1]?.debt_of_month)
              console.log(data)
            } else {
              console.log(data)
              setDeuda(0) 
            }
          })
        }
      
        useEffect(() => {
          // setLoader(true)
          const TOKEN = window.localStorage.getItem("loggedToken")
          const PROFILE_ID = window.localStorage.getItem("profileId")
          const WALLET_ID = window.localStorage.getItem("walletId")
      
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          // .then(data => setPrefCurrency(data.pref_currency))
          .then(data => DeudasMesesAnteriores(data))
          // .finally(        BringDatos())
        },[])
      
        async function DeudasMesesAnteriores(meses){
          // const lastMonth = meses[meses.length - 1]?.name
          const MONTH_ID = window.localStorage.getItem("monthId")
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/debt/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          await fetch(url,{ method:"POST",headers, withCredentials:true })
          .then(response => response.json())
          .then(data => {          
            if(data.monthly_totals[data.monthly_totals.length -1]?.month_id !== MONTH_ID){
              data.monthly_totals?.pop()
              setDeudaMesesAnteriores(data.monthly_totals?.reduce((sum, obj) => { return sum + obj.debt_of_month; }, 0))
              console.log(data)
            } else {
              console.log(data)
              setDeudaMesesAnteriores(0)
            }
          })
        }

      function renderCurrency(){
        if(prefCurrency === 1){
          return "USD"
        } else if (prefCurrency === 2){
          return "MXN"
        } else {
          return "EUR"
        }
      }



  return (
    <>
    <Header />
    <Sidebar
      setShow={setShowContra} 
      setPasswordAction={setPasswordAction}
    />
    <div className={window.localStorage.getItem("light") ? "cat_container_light":"cat_container"}>
        {/* <button onClick={DescargaArchivo}>Descargar transacciones</button> */}
        <div className='add_cat_container'>
            {window.localStorage.getItem("editable")
                  ? <button className='add_category' onClick={() => setShowAddCategory(!showAddCategory)}><img src={fondos_svgg} alt="add category" />Agregar Producto</button>
                  : <button className='add_category'><img src={fondos_svgg} alt="add category" />Agregar Producto</button>
            } 
              {/* <div className="balance_cat">
                <p>Total de Productos: <span>{renderCurrency()}${totalProductos.toLocaleString("es-MX")}</span></p>
              </div>
              <div className="balance_cat">
                <p>Total Adeudado: <span>{renderCurrency()}${adeudados.toLocaleString("es-MX")}</span></p>
              </div>
              <div className="balance_cat">
                <p>Total Abonado: <span>{renderCurrency()}${totalDeudas.toLocaleString("es-MX")}</span></p>
              </div> */}
              <div className="balance_cat">
                <p>Deuda Meses Anteriores: <span>{renderCurrency()}${deudaMesesAnteriores.toLocaleString("es-MX") || 0}</span></p>
              </div>
              <div className="balance_cat">
                <p>Deuda Mes Actual: <span>{renderCurrency()}${deuda.toLocaleString("es-MX") || 0}</span></p>
              </div>
              <div className="group_cat_end">
              <Link to="/resumen-prod" target="blank"> <button className="btn_cat_resumen">Descargar Resumen</button></Link>
              {months?.id === Number(window.localStorage.getItem("monthId")) || (allMonths?.length === 1 && months.id !== Number(window.localStorage.getItem("monthId"))) 
                  ? <button className="btn_cat_resumen" onClick={() => {setShowAlert(!showAlert)}}>Comenzar Nuevo Mes</button>
                  : <button className="btn_cat_resumen_mal" onClick={() => {setShowContra(true)}}>Comenzar Nuevo Mes</button>
              }
                {allMonths?.length === 0 
                    ? <p className='visual_month'>Mes visualizado <span>Ninguno</span></p>
                    : <p className='visual_month'>Mes visualizado <select className="month_selector" onChange={HandleMonth}>{allMonths?.map((month) => (
                        month.id === Number(window.localStorage.getItem("monthId")) 
                          ? <option className="option_month" selected key={month.id} value={month.id}>{month.name}</option>
                          : <option className="option_month" key={month.id} value={month.id}>{month.name}</option>
                    ))}</select></p>
                }
              </div>
        </div>
        <div className="full_table">
          <div className='cat_title_container'>
            <p className='cat_title_1'>Productos</p>
          </div>
            <div className='table_header'>
              <p className='header_date'>Total de Productos</p>
              <p className='header_title3'>Nombre</p>
              <p className='header_abonado'>Abonado</p>
              <p className='header_deuda'>Deuda</p>
              <p className='header_total'>Total</p>
              <p className='dot_inv'>.</p>
            </div>
          <div className='cat_table'>
            <ul>
              {window.localStorage.getItem("compartida") 
              ? 
                <>
              {sharedCategorias?.map((e) => (
                <li key={e.id}>
                  <RowProductos
                    walletId={e.idWallet}
                    dueñoId={e.user}
                    categorias={productos} 
                    cambioCategorias={setProductos} 
                    nombreCat={e.name} 
                    identificador={e.id} 
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    showAddTransaction={showAddProdTransac}
                    setShowAddTransaction={setShowAddProdTransac}
                    setCategoryId={setCategoryId}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setApiUrl={setApiUrl}
                    compartida={false}
                    setIfShared={setIfShared}
                    setShowTransfer={setShowTransfer}
                    setWalletForTransac={setWalletForTransac}
                    setCantProd={setCantProd}
                    setNameProd={setNameProd}
                    setPriceProd={setPriceProd}
                    setIconProd={setIconProd}
                    amount={e.amount}
                    price={e.price}
                    icon={e.icon}
                    editable={true}
                    compartido={compartido}
                    walletName={walletName}
                    gastos={gastos}
                  />
                </li>
              ))}
              {sharedCategoriasVisual?.map((e) => (
                <li key={e.id}>
                  <RowProductos
                    walletId={e.idWallet}
                    dueñoId={e.user}
                    categorias={productos} 
                    cambioCategorias={setProductos} 
                    nombreCat={e.name} 
                    identificador={e.id} 
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    showAddTransaction={showAddProdTransac}
                    setShowAddTransaction={setShowAddProdTransac}
                    setCategoryId={setCategoryId}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setApiUrl={setApiUrl}
                    compartida={false}
                    setIfShared={setIfShared}
                    setShowTransfer={setShowTransfer}
                    setWalletForTransac={setWalletForTransac}
                    setCantProd={setCantProd}
                    setNameProd={setNameProd}
                    setPriceProd={setPriceProd}
                    setIconProd={setIconProd}
                    amount={e.amount}
                    price={e.price}
                    icon={e.icon}
                    editable={false}
                    compartido={compartido}
                    walletName={walletName}
                    gastos={gastos}
                  />
                </li>
              ))}
                </>
              :
              productos?.map((e) => (
                <li key={e.id}>
                  <RowProductos
                    walletId={e.idWallet}
                    dueñoId={e.user}
                    categorias={productos} 
                    cambioCategorias={setProductos} 
                    nombreCat={e.name} 
                    identificador={e.id} 
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    showAddTransaction={showAddProdTransac}
                    setShowAddTransaction={setShowAddProdTransac}
                    setCategoryId={setCategoryId}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setApiUrl={setApiUrl}
                    compartida={false}
                    setIfShared={setIfShared}
                    setShowTransfer={setShowTransfer}
                    setWalletForTransac={setWalletForTransac}
                    setCantProd={setCantProd}
                    setNameProd={setNameProd}
                    setPriceProd={setPriceProd}
                    setIconProd={setIconProd}
                    total={e.total}
                    amount={e.amount}
                    price={e.price}
                    icon={e.icon}
                    editable={true}
                    compartido={compartido}
                    walletName={walletName}
                    gastos={gastos}
                  />
                </li>
              ))          
              }


            </ul>
          </div>
        </div>
        <div className="full_table2">
          {/* <button className='add_category transac_btn' onClick={() => setShowAddTransac(!showAddTransac)}><img src={add_cat} alt="add category" />Transacciones</button> */}
          <div className='cat_title_container'>
            <p onClick={HandleTransactionClick1} className={activeIngresos ? 'cat_egresos' : 'cat_ingresos'}>Pagos</p>
            <p onClick={HandleTransactionClick2} className={activeEgresos ? 'cat_egresos' : 'cat_ingresos'}>Disponibles</p>
            <div className="filtrado_transac">
                  <span>Filtrar:</span>
                  <select onChange={HandleTransacFilter}>
                    <option value={0}>Todas</option>
                    <option value={1}>1 dia</option>
                    <option value={3}>3 dias</option>
                    <option value={7}>7 dias</option>
                    <option value={15}>15 dias</option>
                  </select>
                </div>
          </div>
          {/* SI ACTIVEINGRESOS ES TRUE FILTRAR LA TABLA POR UNOS U OTROS */}
            <div className='table_header2'>
                <p className='header_title1'>Fecha</p>
                {activeEgresos
                  ? <p className='header_title3'>Producto</p>
                  : <p className='header_title3'>Producto</p>
                }
                
                <p className='header_title_2'>Precio individual</p>
                <p className='header_title3'>Resta</p>
                <p className='header_title3'>TOTAL</p>
                <p className='dot_inv'>.</p>
            </div>
          <div className='cat_table'>
            {activeIngresos 
              ? <ul>
                {window.localStorage.getItem("compartidas")
                  ?
                  <>
                    {sharedTransacciones?.map((transac) => (
                      <TablaTransProd
                        key={transac.id}
                        date={transac.date} 
                        walletId={transac.idWallet}
                        clave={transac.name}
                        comments={transac.comments}
                        profile_name={transac.product_price}
                        currAmount={transac.currAmount}
                        idCategory={transac.idCategory}  
                        idProduct={transac.idProduct}  
                        categorias={productos}
                        identificador={transac.id}
                        showDate={showDate}
                        setShowDate={setShowDate}
                        showConfirmation={showConfirmation}
                        setShowConfirmation={setShowConfirmation}
                        showComments={showComments}
                        setShowComments={setShowComments}
                        setApiUrl={setApiUrl}
                        setCategoryId={setCategoryId}
                        setComments={setComments}
                        transactions={transacciones}
                        withCategory={false}
                        pendiente={false}
                        editable={true}
                        productos={productos}
                        walletName={walletName}
                        showModify={showModifyProdTrans}
                        setShowModify={setShowModifyProdTrans}
                        setModifyId={setModifyId}
                        setMontoTrans={setMontoTrans}
                        setShowPassword={setShowPassword}
                      />
                    ))}
                    {sharedTransaccionesVisibles?.map((transac) => (
                      <TablaTransProd
                        key={transac.id}
                        date={transac.date} 
                        walletId={transac.idWallet}
                        clave={transac.name}
                        comments={transac.comments}
                        profile_name={transac.product_price}
                        currAmount={transac.currAmount}
                        idCategory={transac.idCategory}  
                        idProduct={transac.idProduct}  
                        categorias={productos}
                        identificador={transac.id}
                        showDate={showDate}
                        setShowDate={setShowDate}
                        showConfirmation={showConfirmation}
                        setShowConfirmation={setShowConfirmation}
                        showComments={showComments}
                        setShowComments={setShowComments}
                        setApiUrl={setApiUrl}
                        setCategoryId={setCategoryId}
                        setComments={setComments}
                        transactions={transacciones}
                        withCategory={false}
                        pendiente={false}
                        editable={false}
                        productos={productos}
                        walletName={walletName}
                        showModify={showModifyProdTrans}
                        setShowModify={setShowModifyProdTrans}
                        setModifyId={setModifyId}
                        setMontoTrans={setMontoTrans}
                        setShowPassword={setShowPassword}
                      />
                    ))}
                  </>
                  :
                  transacciones?.map((transac) => (
                    <TablaTransProd
                      key={transac.id}
                      date={transac.date} 
                      walletId={transac.idWallet}
                      clave={transac.name}
                      comments={transac.comments}
                      profile_name={transac.product_price}
                      currAmount={transac.currAmount}
                      idCategory={transac.idCategory}  
                      idProduct={transac.idProduct}  
                      categorias={productos}
                      identificador={transac.id}
                      showDate={showDate}
                      setShowDate={setShowDate}
                      showConfirmation={showConfirmation}
                      setShowConfirmation={setShowConfirmation}
                      showComments={showComments}
                      setShowComments={setShowComments}
                      setApiUrl={setApiUrl}
                      setCategoryId={setCategoryId}
                      setComments={setComments}
                      transactions={transacciones}
                      withCategory={true}
                      pendiente={false}
                      editable={true}
                      productos={productos}
                      walletName={walletName}
                      showModify={showModifyProdTrans}
                      setShowModify={setShowModifyProdTrans}
                      setModifyId={setModifyId}
                      setMontoTrans={setMontoTrans}
                      setShowPassword={setShowPassword}
                    />
                  ))
                }

                </ul>
            : 
             <ul>
              {window.localStorage.getItem("compartidas")
                ?
                <>
                  {transaccionesPendientes?.map((prod) => (
                  <TablaDisponibles 
                    key={prod.id}
                    // date={prod.date} 
                    walletId={prod.idWallet}
                    clave={prod.name}
                    comments={prod.comments}
                    profile_name={prod.price}
                    currAmount={prod.amount}
                    idCategory={prod.idCategory} 
                    categorias={productos}
                    identificador={prod.id} 
                    date={prod.date}
                    showModify={showModifyProdTrans}
                    setShowModify={setShowModifyProdTrans}
                    setModifyId={setModifyId}
                    // showDate={showDate}
                    setShowTransfer={setShowTransfer}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    // showComments={showComments}
                    // setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    // setComments={setComments}
                    setCategoryId={setCategoryId}
                    // transactions={transacciones}
                    withCategory={true}
                    editable={true}
                    setMontoTrans={setMontoTrans}
                    setShowPassword={setShowPassword}
                    // pendiente={false}
                    />
                  ))}
                  {transaccionesPendientesVisibles?.map((prod) => (
                  <TablaDisponibles 
                    key={prod.id}
                    // date={prod.date} 
                    walletId={prod.idWallet}
                    clave={prod.name}
                    comments={prod.comments}
                    profile_name={prod.price}
                    currAmount={prod.amount}
                    idCategory={prod.idCategory} 
                    categorias={productos}
                    identificador={prod.id} 
                    date={prod.date}
                    showModify={showModifyProdTrans}
                    setShowModify={setShowModifyProdTrans}
                    setModifyId={setModifyId}
                    // showDate={showDate}
                    setShowTransfer={setShowTransfer}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    // showComments={showComments}
                    // setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    // setComments={setComments}
                    setCategoryId={setCategoryId}
                    // transactions={transacciones}
                    withCategory={true}
                    editable={false}
                    setMontoTrans={setMontoTrans}
                    setShowPassword={setShowPassword}
                    // pendiente={false}
                    />
                  ))}
                </>
                :
                productos?.map((prod) => (
                  <TablaDisponibles
                    key={prod.id}
                    total={prod.total}
                    // date={prod.date} 
                    walletId={prod.idWallet}
                    clave={prod.name}
                    comments={prod.comments}
                    profile_name={prod.price}
                    currAmount={prod.amount}
                    idCategory={prod.idCategory} 
                    categorias={productos}
                    identificador={prod.id} 
                    date={prod.date}
                    showModify={showModifyProdTrans}
                    setShowModify={setShowModifyProdTrans}
                    setModifyId={setModifyId}
                    // showDate={showDate}
                    setShowTransfer={setShowTransfer}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    // showComments={showComments}
                    // setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    // setComments={setComments}
                    setCategoryId={setCategoryId}
                    // transactions={transacciones}
                    withCategory={true}
                    editable={true}
                    setMontoTrans={setMontoTrans}
                    setShowPassword={setShowPassword}
                    // pendiente={false}
                  />
                ))
              }

            </ul>
            }
          </div>
        </div>
    </div>
    {showAddCategory 
      ? <AgregarProducto
          setShow={setShowAddCategory}
          show={showAddCategory} 
        /> 
      : ""
    }
    {showAddProdTransac 
      ? <AgregarProdTransac
          setShow={setShowAddProdTransac}
          show={showAddProdTransac} 
          categoryId={categoryId}
          ifShared={ifShared}
          walletForTransac={walletForTransac}
        /> 
      : ""
    }
    {showModifyCategory 
      ? <ModificarProducto
          setShow={setShowModifyCategory}
          show={showModifyCategory} 
          modifyId={modifyId}
          cantProd={cantProd}
          priceProd={priceProd}
          nameProd={nameProd}
          iconProd={iconProd}
        /> 
      : ""
    }
    {showModifyProdTrans 
      ? <ModificarTransProd
          setShow={setShowModifyProdTrans}
          show={showModifyProdTrans} 
          modifyId={modifyId}
          cantProd={cantProd}
          priceProd={priceProd}
          nameProd={nameProd}
          iconProd={iconProd}
          montoTrans={montoTrans}
        /> 
      : ""
    }
    
    {showConfirmation
      ? <ConfirmDelete show={showConfirmation} setShow={setShowConfirmation} identificador={categoryId} apiUrl={apiUrl} />
      : ""
    }
    {showDate
      ? <ChangeDate show={showDate} setShow={setShowDate} identificador={categoryId} />
      : ""
    }
    {showComments
      ? <AgregarComentario
          show={showComments}   
          setShow={setShowComments} 
          identificador={categoryId}  
          comments={comments}
        />
      : ""
    }
    {showTransfer
      ? <AddProduct
          show={showComments}   
          setShow={setShowTransfer} 
          identificador={categoryId} 
          categories={productos}
          sharedCategories={sharedCategorias} 
        />
      : ""
    }
    { loader 
      ? <Loading /> 
      : ""
    }
    {/* {showContra
      ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    } */}
    {window.localStorage.getItem("prevMonth") && !window.localStorage.getItem("aproved")
      ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    }
    {showPassword
      ? <IngresarContraseñaModificar setShowModifyProdTrans={setShowModifyProdTrans} setShowPassword={setShowPassword} show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    }
    {showAlert 
      ? <NewMonth show={showAlert} setShow={setShowAlert} showMonth={showMonth} setShowMonth={setShowMonth} /> 
      : ""
    }
    
</>
  )
}

export default CategoriasProductos

function ConfirmDelete({show, setShow, identificador, apiUrl}) {

    const TOKEN = window.localStorage.getItem("loggedToken")
    const [newComment, setNewComment] = useState("")
  
    async function deleteRow(){
      setShow(!show)
      try {
          const newName = {comment:newComment}
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/${apiUrl}del/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body:JSON.stringify(newName)
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Deleted successfully');
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className={window.localStorage.getItem("light") ? "confirmacion_exitoso_container_light":"confirmacion_exitoso_container"}>
          <div className="conf_subcontainer">
              <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
              <h2>{`¿Seguro que desea eliminar este elemento?`}</h2>
              <form>
                <label htmlFor="nuevoComentario">Comentario (opcional)</label>
                <input 
                    type="text"
                    id='nuevoComentario'
                    placeholder='Comentario...'
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />
              </form>
              <div className='flex_conf'>
                <button className='confirm_delete' onClick={deleteRow}>Si</button>
                <button className='deny_delete' onClick={() => setShow(!show)}>No</button>
              </div>
          </div>
      </div>
    )
  }
  
  function ChangeDate({show, setShow, identificador}) {
  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const [date, setDate] = useState("")
    const [newComment, setNewComment] = useState("")
  
    async function UpdateDate(){
      setShow(!show)
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/date/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify({date:date, comment:newComment})
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Changed successfully');
          // console.log(response);
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className={window.localStorage.getItem("light") ? "confirmacion_date_container_light":"confirmacion_date_container"}>
          <div className="conf_subcontainer">
              <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
              <form onSubmit={UpdateDate}>
                <label>Nueva fecha:</label>
                {/* <span>Recuerde de usar "-" entre los números.</span> */}
                <input type="date"
                  required
                  placeholder='yyyy-mm-dd'
                  value={date}
                  onChange={(e) => {setDate(e.target.value)}}
                  // max={new Date().toISOString().split('T')[0]}
                />
                <label htmlFor="nuevoComentario">Comentario (opcional)</label>
                <input 
                    type="text"
                    id='nuevoComentario'
                    placeholder='Comentario...'
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />
                <button type='submit'>Cambiar</button>
              </form>
          </div>
      </div>
    )
  }
  
  function AddProduct({show, setShow, identificador, sharedCategories, categories}) {
  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const [transferAmount, setTransferAmount] = useState("")
    const [newComment, setNewComment] = useState("")
    const [activeIngresos, setActiveIngresos] = useState(true);
    const [activeEgresos, setActiveEgresos] = useState(false);
    const [automatic, setAutomatic] = useState(true);

    function HandleTransactionClick1() {
      setActiveIngresos(true);
      setActiveEgresos(false);
    }
    function HandleTransactionClick2() {
      setActiveIngresos(false);
      setActiveEgresos(true);
    }

    function HandleKeyPress(e){
      if (e.key === ',' || e.key === '.') {
        // console.log('Enter key pressed!');
        setAutomatic(false)
    }
    }
    function HandleMontoChange(e){
        if(isNaN(e.target.value) && e.target.value.length < 3){
          setTransferAmount(0)
        } else {
          const inputValue = e.target.value;
          const formattedNumber = Number(inputValue.replace(/,/g,"")).toLocaleString('es-MX');
          setTransferAmount(formattedNumber)
        }
    }
    function HandleMontoChangeManual(e){
        if( e.target.value.length < 1){
          setTransferAmount(0)
        } else {
          const inputValue = e.target.value;
          const formattedNumber = inputValue;
          setTransferAmount(formattedNumber)
        }
    }

    async function TransferToCat(e){
      e.preventDefault()
      // setShow(!show)
      // const PROFILE_ID = window.localStorage.getItem("profileId")      
      if(activeIngresos){
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/add/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: JSON.stringify({comment:newComment,idProduct:Number(identificador), amount:Number(transferAmount.replace(/,/g, ""))})
          });
          if (response.status === 204 || response.status === 200) {
            // setLoader(false)
            console.log('Changed successfully');
            // console.log(response);
            // const data = await response.json();
            window.location.reload()
            // console.log(data);
          } else {
            window.location.reload()
              // console.log(response.status);
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
        } catch (error) {
          console.log(error);
        }
      }
      else if (activeEgresos) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/add/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify({comment:newComment, idProduct:Number(identificador), amount:(Number(transferAmount.replace(/,/g, "")).toFixed(2)*(-1))})
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Changed successfully');
          // console.log(response);
          // const data = await response.json();
          window.location.reload()
          // console.log(data);
        } else {
          window.location.reload()
            // console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
      }
    }
    
    return (
      <div className={window.localStorage.getItem("light") ? "confirmacion_date_container_light":"confirmacion_date_container"}>
          <div className="conf_subcontainer">
              <img onClick={() => setShow(false)} src={cross_exit} alt="cerrar" />
              <form onSubmit={TransferToCat}>
                {/* <span>Recuerde de usar "-" entre los números.</span> */}
                <label>Cantidad a agregar o quitar:</label>
                <div className="ing_egr">
                <p
                  onClick={HandleTransactionClick1}
                  className={activeIngresos ? "cat_egresos" : "cat_ingresos"}
                >
                Agregar
                </p>
                <p
                  onClick={HandleTransactionClick2}
                  className={activeEgresos ? "cat_egresos" : "cat_ingresos"}
                >
                Quitar
                </p>
                </div>
                <input type="text"
                  required
                  placeholder='xxxxxx'
                  value={transferAmount}
                  onChange={automatic ? HandleMontoChange : HandleMontoChangeManual}
                  onKeyDown={HandleKeyPress}
                  // max={new Date().toISOString().split('T')[0]}
                />
                <label htmlFor="nuevoComentario">Comentario (opcional)</label>
                <input 
                    type="text"
                    id='nuevoComentario'
                    placeholder='Comentario...'
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />
                <button>Guardar</button>
              </form>
          </div>
      </div>
    )
  }
  
  function IngresarContraseña({show, setShow, setAllowAction, passwordAction}) {
  
  
    const [password, setPassword] = useState("")
    const [showContraError, setShowContraError] = useState(false)
  
    const navigate = useNavigate()
  
    async function handleContraSubmit(e){
      e.preventDefault();
      const TOKEN = window.localStorage.getItem("loggedToken")
      const formCategoryData = {password:password}
      
      // console.log(formCategoryData);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pass/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data.detail);
          window.localStorage.setItem("aproved", true)
          window.location.reload()
          setAllowAction(true)
          setShow(false)
          if(passwordAction === "categorias"){
            navigate("/categorias")
          } else if (passwordAction === "automatizaciones") {          
            navigate("/automatizaciones")
          }
          } else {
            // setLoader(false)
            setShowContraError(true)
            console.log(response.status);
            setPassword("")
            
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }
  
  return (
    <div className={window.localStorage.getItem("light") ? "add_category_container_light":"add_category_container"}>
        <form onSubmit={handleContraSubmit}>
            <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
            <label htmlFor="contraseña">Ingrese contraseña</label>
            {showContraError
            ? <span style={{color: "#e74504", fontSize:"14px"}}>Contraseña Incorrecta</span>
            : ""
            }
            <input 
                type="password"
                id='contraseña'
                placeholder='Contraseña...'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
  }

  function IngresarContraseñaModificar({setShowModifyProdTrans, setShowPassword, show, setShow, setAllowAction, passwordAction}) {


    const [password, setPassword] = useState("")
    const [showContraError, setShowContraError] = useState(false)
  
    const navigate = useNavigate()
  
    async function handleContraSubmit(e){
      e.preventDefault();
      const TOKEN = window.localStorage.getItem("loggedToken")
      const formCategoryData = {password:password}
      
      // console.log(formCategoryData);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pass/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data.detail);
          window.localStorage.setItem("aproved", true)
          setShowPassword(false)
          // window.location.reload()
          setAllowAction(true)
          setShow(false)
          if(passwordAction === "categorias"){
            navigate("/categorias")
          } else if (passwordAction === "automatizaciones") {          
            navigate("/automatizaciones")
          }
          } else {
            // setLoader(false)
            setShowContraError(true)
            console.log(response.status);
            setPassword("")
            
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }
  
  return (
    <div className={window.localStorage.getItem("light") ? "add_category_container_light":"add_category_container"}>
        <form onSubmit={handleContraSubmit}>
            <img onClick={() => {setShowModifyProdTrans(false);setShowPassword(false)}} src={cross_exit} alt="close" />
            <label htmlFor="contraseña">Ingrese contraseña</label>
            {showContraError
            ? <span style={{color: "#e74504", fontSize:"14px"}}>Contraseña Incorrecta</span>
            : ""
            }
            <input 
                type="password"
                id='contraseña'
                placeholder='Contraseña...'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
  }

  function NewMonth({show, setShow, showMonth, setShowMonth}) {

    const TOKEN = window.localStorage.getItem("loggedToken")
    const PROFILE_ID = window.localStorage.getItem("profileId")
    const WALLET_ID = window.localStorage.getItem("walletId")
  
    async function confirmMonth(){
      try {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/months/new/${PROFILE_ID}/${WALLET_ID}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
        })
        .then(response => console.log(response))
        // .then(data => console.log(data))
        setShow(!show)
        setShowMonth(!showMonth)
        // const TOKEN = window.localStorage.getItem("loggedToken")
        // const PROFILE_ID = window.localStorage.getItem("profileId")
        const formBudgetData = { budget: 0 }
        
        console.log(formBudgetData);
    
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/budget/${PROFILE_ID}/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: JSON.stringify(formBudgetData)
          });
          
          if (response.status === 200) {
            // setLoader(false)
            console.log('Form submitted successfully');
            const data = await response.json();
            console.log(data);
            // window.location.reload()
            } else {
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
          } catch (error) {
            console.error('Error:', error);
          }
          // const PROFILE_ID = window.localStorage.getItem("profileId")
  
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
  
          await fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          .then(data =>  window.localStorage.setItem("monthId",data.months[data.months.length - 1].id))
        window.location.reload()
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className={window.localStorage.getItem("light") ? "confirmacion_month_container_light":"confirmacion_month_container"}>
          <div className="conf_subcontainer">
              <img onClick={() => {setShow(!show)}} src={cross_exit} alt="cerrar" />
              <h2>¿Seguro que desea comenzar un nuevo mes?</h2>
              <div className='flex_conf'>
                <button className='confirm_delete' onClick={confirmMonth}>Si</button>
                <button className='deny_delete' onClick={() => setShow(!show)}>No</button>
              </div>
          </div>
      </div>
    )
  }  
  
  